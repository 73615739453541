.home-body-message {
    color: #495057;
    font: 28px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    text-align: center;
    margin-top: 24%;
}

.home-body {
    color: #495057;
    font: 28px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    text-align: center;
    margin-top: 15%;
}

.error {
    color: orangered;
}

.success {
    color: green;
}

.header-color {
    background-color: white;
    margin: 0px 50px 0px 50px;
    height: 80px;
}

.topic-header-color {
    background-color: white;
    margin: 0px 50px 0px 50px;
}

.topic-header-color-2 {
    background-color: white;
    margin: 0px 55px 0px 55px;
}

.topic-header-color-mobileView {
    background-color: white;
}

.bottom-app-bar {
    margin: 0px 50px 0px 50px;
}

.header-text-color {
    color: #f16623;
    font-family: 'Open Sans', sans-serif;
}

.dashboard-text-color {
    color: #347f58;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.dashboard-text {
    font-size: 48px;
    color: #347f58;
    font-family: 'Open Sans', sans-serif;
}

.dashboard-text-md {
    font-size: 14px;
    color: #347f58;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.dashboard-sub-text {
    font-size: 11px;
    color: gray;
    font-family: 'Open Sans', sans-serif;
}

.graph-sub-text {
    font-size: 12px;
    color: gray;
    font-family: 'Open Sans', sans-serif;
}

.error-main {
    color: orangered;
    font-size: 15px;
    text-align: center;
}

.custom-btn-success-test {
    background-color: #487cab;
    color: #487cab;
}

.frame-full-height {
    height: 85vh;
    display: inline-block;
    margin: 0 auto;
}

.header-font {
    color: white;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
}

.vertical-line {
    border-left: 1px solid gray;
    height: 40px;
    margin: 0px 5px 0px 5px;
}

.marginTop {
    margin-top: 25px;
}

.drawerItems {
    font-family: 'Open Sans', sans-serif;
    color: #a19b9b;
}

.sideDrawerItems {
    font-family: 'Open Sans', sans-serif;
    color: #404040;
}

.sideDrawerItemsText {
    font-size: 14px;
    font-family: 'Poppins';
}

.drawerFooterItems {
    font-family: 'Open Sans', sans-serif;
    color: white;
}

.drawerItemsText {
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
}

.drawerSubItemsText {
    margin-left: -50px;
    font-family: 'Open Sans', sans-serif;
}

.selectTopMargin {
    margin-top: 16px;
}

.buttonTopMargin {
    margin-top: 0px;
}

.custom-button {
    background-color: #0079c2;
}

.ag-theme-alpine .ag-header-cell {
    background-color: #c2272d;
    color: white;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
}

.table-header-custom {
    background-color: #c2272d;
}

.table-row-custom {
    color: white;
}

.grid-row-even {
    background-color: #f2f2f2 !important;
}

.grid-row-odd {
    background-color: white !important;
}

.ag-row-hover {
    background-color: #ddd !important;
}

.ag-header-cell-label {
    justify-content: center;
}

.my-class {
    text-align: center;
}

.MuiBadge-anchorOriginTopRightRectangle {
    top: 0;
    right: 0;
    transform: scale(1) translate(0%, -0%);
    transform-origin: 100% 0%;
}

.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%);
}

.infinite-scroll-component {
    height: auto;
    overflow: hidden;
}

.custom-css-class {
    display: none;
}

.btnFacebook {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background: white;
    color: #333233;
    border: 0px transparent;
    margin: 0px;
    display: inline-block;
    font-size: 16px;
    margin-top: 15px;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
    cursor: pointer;
    text-align: left;
}

.fa-facebook {
    background: #3B5998;
    color: #FFFFFF;
    padding: 0.3em 0.5em;
}

.circle {
    border-radius: 50%;
    margin: 0.15em;
    font-size: 3em;
    margin-left: 7px;
}

.Component-root-1 {
    display: block !important;
    padding-bottom: 5rem;
    background-color: #f6f7f2;
}

.jss1 {
    display: block !important;
    padding-bottom: 5rem;
    background-color: #f6f7f2;
}

[xmlns~='http://www.w3.org/2000/svg'] {
    width: 60px !important;
    height: 60px !important;
    margin-left: 80px !important;
}

@media only screen and (max-width: 600px) {
    [xmlns~='http://www.w3.org/2000/svg'] {
        width: 60px !important;
        height: 60px !important;
        margin-left: 10px !important;
    }
}

/* span:contains('Continue with Google') {
    margin-left: 20px !important;
} */

#dialogContentDiv div>div {
    border-radius: 10px !important;
}

#chkTermsConditions span>span>svg {
    color: #4caf50 !important;
}

@media only screen and (max-width: 600px) {
    #chkTermsConditions span>span>svg {
        color: #4caf50 !important;
        margin-right: 12px !important;
        margin-left: -11px;
    }
}

.ck-editor__editable_inline {
    background-color: #e7e7e7 !important;
}

.MuiListItem-root {
    padding-top: 0px !important;
    padding-bottom: 2px !important;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.topics-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
}

.likes-comments {
    font-size: 13px !important;
    font-family: 'poppins' !important;
    font-weight: 500 !important;
}

.icon-small-size {
    font-size: 1rem !important;
    color: #333233;
    margin-right: -20px;
}

icon-small-size-mobile {
    font-size: 1rem !important;
    color: #333233;
}

.menu-focus {
    color: '#c2272d' !important;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0px 0 !important;
}

.MuiAccordionSummary-root {
    display: flex;
    padding: 0px 0px !important;
    min-height: 48px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiAccordion-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}